.gridItem {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  justify-content: space-between;
}

.icon {
  max-width: 180px;
  max-height: 67px;
}

.image-preview {
  padding-top: 4px;
  padding-bottom: 3px;
  max-width: 180px;
  max-height: 60px;
}

.browser-icons {
  font-size: 50px;
}

.folderName {
  position: relative;
  padding-bottom: 10px;
}

.actions {
  display: flex;
  height: 35px;
  width: 100%;
  align-self: self-end;
}

.buttons {
  text-align: center;
  width: 50%;
}

.delete-icon,
.share-icon {
  padding: 7px;
  cursor: pointer;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.gridItem:hover {
  background-color: #f5f5f5;
}

.actionButton:hover {
  background-color: #fff;
}

.delete-icon {
  color: #ff7f7f;
}

.actionButton:hover {
  .share-icon {
    color: #007aff;
  }
  .delete-icon {
    color: red;
  }
}

@media (prefers-color-scheme: dark) {
  .gridItem:hover {
    background-color: #222;
  }

  .actionButton:hover {
    background-color: #1a1a1a;
  }
}
