.title {
  position: relative;
  margin: 20px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
}

.title h1 {
  margin: 0px;
}

.control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.control p {
  margin: 0px;
  margin-left: 20px;
}

.name {
  display: flex;
}

.card {
  display: block;
  padding: 0;
  min-width: min-content;
}

.details-icon {
  padding: 7px 14px 7px 0px;
  font-size: 22px;
  cursor: pointer;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.details-icon:hover {
  color: #007aff;
}

.name,
.name:hover,
.name:visited {
  color: inherit;
}
