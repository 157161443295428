.buttons {
  display: flex;
  margin-top: 20px;
}

.comment {
  border: 1px solid;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0px;
}

.value {
  margin-top: 0;
  margin-bottom: 20px;
}

.date {
  margin: 0;
  font-size: 14px;
  font-style: italic;
}

.rejectedIcon {
  margin-top: 5px;
  border-radius: 100%;
  padding: 5px;
  font-size: 18px;
  cursor: pointer;
}

.comment-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.comment {
  border-color: #e0e0e0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 5px 0px;
}

.comment-box {
  box-shadow: inset rgba(99, 99, 99, 0.2) 0px 1px 5px 0px;
}

.date {
  color: #999;
}

.rejectedIcon:hover {
  background-color: #ff7f7f;
}

@media (prefers-color-scheme: dark) {
  .comment {
    border-color: #555;
  }

  .rejectedIcon {
    color: #e0e0e0;
  }

  .rejectedIcon:hover {
    color: #1a1a1a;
  }
}
