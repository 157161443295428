.input {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-top: 10px 0;
}

.input input {
  padding-left: 15px;
  font-size: 16px;
  font-family: inherit;
}

.field {
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  margin: 10px 0px 0px 0px;
  height: 35px;
  width: 100%;
}

.label {
  display: flex;
  margin-top: 10px;
}

.note {
  margin-left: 5px;
}

input[type="file"] {
  padding: 0px;
}

input[type="file"]::file-selector-button {
  /* margin: 2px 5px 2px 15px; */
  font: inherit;
  font-weight: 700;
  font-size: 1rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 33px;
  cursor: pointer;
  padding: 0 15px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.field {
  border-color: #e0e0e0;
  color: #333;
}

.note {
  color: #999;
}

.input input:focus {
  outline: none;
  border-color: #007bff4d;
  box-shadow: 0 0 5px 1px #007aff4d;
}

input[type="file"] {
  color: #999;
}

input[type="file"]::file-selector-button {
  background: #007aff;
  border: 1px solid #007aff;
  color: #fff;
}

input[type="file"]::file-selector-button:hover {
  background: #007affcc;
}

@media (prefers-color-scheme: dark) {
  .field {
    background-color: #333;
    border-color: #666;
  }

  .input input {
    color: #fff;
  }
}
