.mainProgressBarDiv {
  box-sizing: border-box;
  width: 100%-5px;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 5px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.abort-button {
  background-color: #e7e4da;
  border: none;
  border-radius: 9px;
  padding: 2px 8px;
  color: #ff7f7f;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}

.hasError {
  color: #ff7f7f;
  font-weight: bold;
}

.alert {
  color: #ff7f7f;
  font-weight: bold;
}

.warning {
  color: #ff863b;
  font-weight: bold;
}

.aborted {
  background-color: #fff4ce;
}

.alerted {
  background-color: #ffe6e6;
}

.emptyProgressBar {
  background-color: #e7e4da;
  border-radius: 100px;
  height: 4px;
  overflow: hidden;
  position: relative;
}
.fillingProgressBar {
  background-color: #007affcc;
  border-radius: 100px;
  height: 100%;
  width: 100%;
  position: absolute;
}

.fillingProgressBarWarning {
  background-color: #ff863b;
  border-radius: 100px;
  height: 100%;
  width: 100%;
  position: absolute;
}

.fillingProgressBarAlert {
  background-color: #ff7f7f;
  border-radius: 100px;
  height: 100%;
  width: 100%;
  position: absolute;
}

.file-details {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.abort-button:hover {
  background-color: red;
  color: #fff;
}

.abort-button:disabled {
  background-color: #e7e4da;
  color: #888;
  cursor: not-allowed;
}
