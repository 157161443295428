/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.row:hover {
  background-color: #e5e5e5;
}

@media (prefers-color-scheme: dark) {
  .row:hover {
    background-color: #222;
  }
}
