.crop-modal {
  display: block;
}

.cropperContainer {
  height: 500px;
  width: 600px;
}

.cropper {
  height: 500px;
}

.editContainer {
  margin: 0 20px;
}

.sliders {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sliderLabel {
  width: 120px;
  margin-right: 20px;
}

.cropButtons {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.editContainer {
  border-top: 1px solid #e0e0e0;
}
