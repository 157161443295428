.checkboxContainer {
  display: flex;
  align-items: center;
}

.checkbox {
  margin-right: 0.5rem;
}

.checkboxLabel {
  display: flex;
  margin-left: 0.5rem;
}

.checkboxLabel p {
  display: flex;
  margin: 0;
  margin-top: 5px;
}

.note {
  margin-left: 5px;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 0.15em;
  cursor: pointer;
  display: flex;
  place-content: center;
}

input[type="checkbox"]::before {
  margin: auto;
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: center;
  transition: 120ms transform ease-in-out;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.note {
  color: #999;
}

input[type="checkbox"] {
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  font: inherit;
  color: inherit;
  border: 1px solid #e0e0e0;
}

input[type="checkbox"]::before {
  box-shadow: inset 1em 1em #fff;
}

input[type="checkbox"]:checked {
  border-color: #007aff;
  background-color: #007aff;
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid #007aff;
  outline-offset: max(2px, 0.15em);
}

@media (prefers-color-scheme: dark) {
  input[type="checkbox"] {
    background-color: #333;
    border-color: #666;
  }
  input[type="checkbox"]::before {
    box-shadow: inset 1em 1em #e0e0e0;
  }
}
