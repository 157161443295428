.menu-button {
  display: flex;
  font-size: 20px;
  border-radius: 5px;
  align-items: center;
}

.kebabMenu {
  position: relative;
}

.options-list {
  position: absolute;
  top: 100%;
  right: 0;
  min-width: max-content;
  width: max-content;
  margin-top: 5px;
  padding: 0;
  list-style: none;
  border-radius: 10px;
  overflow: hidden;
}

.option {
  display: flex;
  height: 30px;
  padding: 5px 10px;
  margin: 10px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.menu-button {
  background-color: inherit;
  color: inherit;
  border-color: #e0e0e0;
}

.menu-button:hover {
  color: #fff;
}

.options-list {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 15px 25px rgba(0, 0, 0, 0.1);
}

.option:hover {
  background-color: #efefef;
}

.delete-option:hover {
  background-color: #ff7f7f;
  color: #fff;
}

@media (prefers-color-scheme: dark) {
  .option {
    color: #fff;
  }

  .options-list {
    background-color: #222;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  }

  .option:hover {
    background-color: #333;
  }
}
