.label {
  display: flex;
  margin-top: 10px;
}

.note {
  margin-left: 5px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.note {
  color: #999;
}
