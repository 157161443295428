.title {
  position: relative;
  margin: 0px;
  text-align: left;
  margin-left: 20px;
}

.title h1 {
  margin: 0px;
}
.title p {
  margin-top: 5px;
}

.checkbox-header {
  display: flex;
  align-items: center;
}

.control {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.control-buttons {
  display: flex;
}

.card {
  display: block;
  padding: 0;
  min-width: min-content;
}

.edit-icon,
.delete-icon {
  padding: 7px;
  font-size: 22px;
  cursor: pointer;
}

.create-button {
  margin: 0 0 20px 20px;
}

.delete-button {
  margin-left: 10px;
}

.clear-button {
  margin-left: 10px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.delete-button {
  background-color: #ff7f7f;
  border-color: #ff7f7f;
}

.delete-button:hover {
  background-color: red;
  border-color: red;
}

.edit-icon:hover {
  color: #007aff;
}

.delete-icon {
  color: #ff7f7f;
}

.delete-icon:hover {
  color: red;
}

.clear-button {
  color: #999;
  background-color: inherit;
  border-color: #999;
}

.clear-button:hover {
  color: inherit;
  background-color: #e0e0e0;
  border-color: #999;
}

.name,
.name:hover,
.name:visited {
  color: inherit;
}
