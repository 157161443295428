.sidebar-link,
.dropdown-link {
  display: flex;
  padding: 5px 10px;
  height: 30px;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
}
.subnav-title {
  display: flex;
  align-items: center;
}
.sidebar-link {
  justify-content: space-between;
  margin: 10px;
  list-style: none;
}
.sidebar-label {
  margin-left: 10px;
  font-size: 14px;
}
.dropdown-link {
  margin: 10px 20px;
  align-items: center;
}

.sidebar-link:hover,
.dropdown-link:hover,
.dropdown-link.active {
  border-radius: 10px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.dropdown-link,
.sidebar-link,
.sidebar-link:hover,
.dropdown-link:hover,
.dropdown-link:visited,
.sidebar-link:visited {
  color: #333;
}

.sidebar-link:hover,
.dropdown-link:hover,
.dropdown-link.active {
  background: #efefef;
}
@media (prefers-color-scheme: dark) {
  .dropdown-link,
  .sidebar-link,
  .sidebar-link:hover,
  .dropdown-link:hover,
  .dropdown-link:visited,
  .sidebar-link:visited {
    color: #ccc;
  }
  .sidebar-link:hover,
  .dropdown-link:hover,
  .dropdown-link.active {
    background: #333;
  }
}
