.breadcrumbs {
  margin-left: 40px;
  font-size: 14px;
}
.breadcrumbs > * {
  display: inline-flex;
  margin-right: 10px;
  align-items: center;
}
.breadcrumbs .crumb:after {
  content: "/";
  margin-left: 10px;
}
.breadcrumbs .crumb:last-child:after {
  display: none;
}

.head {
  display: flex;
  height: 60px;
  align-items: center;
}

/** ----------------------------------------------------------------------------- 
**              Media queries 
**-----------------------------------------------------------------------------*/

@media (max-width: 600px) {
  .breadcrumbs {
    margin-left: 20px;
  }
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.breadcrumbs .crumb:after {
  color: #ccc;
}
.breadcrumbs a {
  color: #333;
}
.breadcrumbs svg {
  position: relative;
  top: 2px;
}

@media (prefers-color-scheme: dark) {
  .breadcrumbs .crumb:after {
    color: #333;
  }
  .breadcrumbs a {
    color: #ccc;
  }
}
