.container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
  display: block;
  width: 60vw;
  margin-bottom: 40px;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.title h2 {
  padding-left: 20px;
}

.kebab {
  padding-right: 20px;
}

.content {
  padding: 20px;
  padding-top: 0px;
}

.details p {
  margin-bottom: 5px;
  font-weight: bold;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.title-container {
  border-bottom: 1px solid #e0e0e0;
}
