.dropzone {
  margin-top: 40px;
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  justify-content: center;
  height: 200px;
}

.dropzoneInner {
  padding: auto;
  text-align: center;
}

.dropIcon {
  font-size: 26px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.listItem {
  position: relative;
  margin: 10px;
}

.upload-title {
  font-size: 20px;
}

.upload-note {
  margin: 5px;
}

.list-card {
  text-align: center;
  height: 150px;
  width: 180px;
  overflow: hidden;
  border-radius: 10px;
}

.img {
  height: 120px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.file-name {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-card p {
  margin: 0;
  font-size: 12px;
}

.deleteIcon {
  position: absolute;
  height: 30px;
  top: -15px;
  right: -15px;
  border-radius: 100%;
  padding: 5px;
  cursor: pointer;
  border: none;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.rejectedList {
  list-style-type: none;
  padding: 0;
}

.rejectedListItem {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.errors {
  font-size: 14px;
}

.rejectedFiles {
  margin: 0;
  margin-top: 10px;
}

.rejectedItem {
  display: block;
  padding-bottom: 5px;
}

.rejectedIcon {
  margin-top: 5px;
  border-radius: 100%;
  padding: 5px;
}

.uploadhr {
  margin-top: 0px;
  margin-bottom: 10px;
  border: none;
  height: 1px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.dropzone {
  border: 2px dashed #e0e0e0;
  background-color: #ffffff;
  color: #e0e0e0;
  outline: none;
}

.list-card {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.deleteIcon {
  background-color: #e0e0e0;
}

.removeButton {
  color: inherit;
  background-color: inherit;
  border-color: #e0e0e0;
}

.removeButton:hover {
  border-color: #ff7f7f;
}

.errors {
  color: #ff7f7f;
}

.rejectedIcon:hover,
.deleteIcon:hover,
.removeButton:hover {
  background-color: #ff7f7f;
}

.uploadhr {
  background: #e0e0e0;
}

@media (prefers-color-scheme: dark) {
  .dropzone {
    background-color: #333;
    color: #888;
    border: 2px dashed #666;
  }

  .list-card {
    box-shadow: 0 10px 15px -3px #9999991a, 0 4px 6px -2px #9999990d;
  }

  .deleteIcon {
    background-color: #888;
    color: #333;
  }

  .rejectedIcon {
    background-color: #e0e0e0;
  }

  .removeButton:hover,
  .uploadButton:hover {
    color: #333;
  }
}
