.card {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 60vw;
  margin-bottom: 40px;
}

.title {
  text-align: left;
  margin-top: 0;
}

.h3 {
  margin-top: 50px;
  margin-bottom: 25px;
}

.submit-button {
  display: flex;
  margin-top: 20px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.error {
  color: #ee5858;
}
