.confirm-container {
  text-align: center;
  width: 500px;
}

.confirm-message {
  text-align: left;
  text-align: center;
  margin: 20px;
}

.confirm-text {
  padding-left: 20px;
}

.confirm-text h3 {
  margin-bottom: 5px;
}

.confirm-text p {
  margin-top: 5px;
  margin-bottom: 30px;
}

.icon-container {
  display: flex;
  justify-content: center;
}

.delete-icon {
  font-size: 40px;
}

.footer {
  display: flex;
  justify-content: space-evenly;
}

.cancel-button,
.delete-button {
  cursor: pointer;
  height: 40px;
  width: 225px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.delete-icon {
  color: #ff7f7f;
}

.cancel-button,
.delete-button {
  background-color: inherit;
  border-color: #e0e0e0;
  color: inherit;
}

.delete-button {
  background-color: #ff7f7f;
  border-color: #ff7f7f;
  color: #fff;
}

.delete-button:hover {
  background-color: red;
  border-color: red;
}

.cancel-button:hover {
  background-color: #007aff;
  color: #fff;
}
