.card {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 60vw;
  margin-bottom: 40px;
}

.title {
  text-align: left;
  margin-top: 0;
}

.select {
  width: 100%;
}

.buttons {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}
.buttons button {
  margin-right: 10px;
}

.submit-button {
  display: flex;
  margin-top: 20px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.delete-link,
.delete-link:visited {
  color: red;
}

.delete-button {
  background-color: #ff7f7f;
  border-color: #ff7f7f;
  color: #fff;
}

.delete-button:hover {
  background-color: red;
  border-color: red;
}
