.services ul {
  padding-left: 20px;
  line-height: 1.6rem;
}

.social-icons {
  font-size: 22px;
}

.social-icon {
  margin: 0 10px;
}

.aboutUs {
  line-height: 1.6rem;
}
