.gridContainer {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 10px;
  box-sizing: border-box;
}

@media (max-width: 1600px) {
  .gridContainer {
    grid-template-columns: repeat(9, 1fr);
  }
}
@media (max-width: 1300px) {
  .gridContainer {
    grid-template-columns: repeat(8, 1fr);
  }
}
@media (max-width: 1100px) {
  .gridContainer {
    grid-template-columns: repeat(7, 1fr);
  }
}
@media (max-width: 1000px) {
  .gridContainer {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (max-width: 800px) {
  .gridContainer {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 700px) {
  .gridContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 560px) {
  .gridContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 440px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
