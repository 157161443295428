.login {
  max-width: 420px;
  width: 75%;
  margin: 30px auto;
  padding: 50px;
}

.login button {
  margin-top: 25px;
  margin-bottom: 10px;
  width: 100%;
}

.img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

.h2 {
  text-align: center;
  margin-bottom: 10px;
}

.link {
  text-align: center;
  margin-top: 15px;
}

.error {
  margin-top: 5px;
  margin-bottom: 10px;
}

.message {
  margin-top: 25px;
  margin-bottom: 0px;
  text-align: center;
}

.note {
  text-align: center;
  margin-top: 0;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.message {
  color: #007aff;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #e593b9;
  background-color: #f7e9f7;
}

.error {
  color: #ee5858;
}
