.container {
  position: relative;
  max-width: 900px;
  max-height: 600px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 95vw;
  min-width: 320px;
  overflow: auto;
}

.title {
  position: sticky;
  top: 0;
  display: flex;
  z-index: 1;
  height: 66px;
  padding-left: 20px;
  justify-content: space-between;
  align-items: center;
}

.content {
  padding: 20px;
  overflow: auto;
}

.container h3 {
  margin: 15px 0px 5px 0px;
}

.close-button {
  font-size: 25px;
  height: 25px;
  padding: 10px;
  cursor: pointer;
  margin-right: 10px;
}

.close-button:hover {
  border-radius: 10px;
}

.submit-container {
  margin-top: 20px;
}

.CV-contanier {
  margin-top: 15px;
}

.CV-file {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.title {
  border-bottom: 1px solid #e0e0e0;
  background-color: #fff;
}

.close-button:hover {
  background: #efefef;
}

.error {
  color: #ee5858;
}

@media (prefers-color-scheme: dark) {
  .title {
    background-color: #1a1a1a;
    border-color: #222;
  }

  .close-button:hover {
    background: #333;
  }
}
