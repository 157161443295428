:root {
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Avenir", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  margin: 10px;
  padding: 10px;
  overflow-x: auto;
  min-height: 50vh;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
}

th {
  padding: 10px;
  padding-left: 20px;
  white-space: nowrap;
}

th button {
  font: inherit;
  padding-left: 0px;
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
}

table,
th,
td {
  text-align: left;
}

td {
  height: 40px;
  white-space: nowrap;
  padding-right: 10px;
  padding-left: 20px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

body {
  background-color: #f6f6f6;
  color: #333;
}

td {
  border-top: 1px solid #e0e0e0;
}

th button {
  color: #333;
}

a {
  color: #007aff;
  text-decoration: none;
}

a:hover,
a:active {
  color: #007affcc;
}

a:visited {
  color: #007aff;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #000;
    color: #ccc;
  }

  th button {
    color: inherit;
  }

  td {
    border-color: #222;
  }
}
