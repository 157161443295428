.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  font-size: 14px;
  top: -25px;
  transform: translateX(-70%);
  padding: 5px;
  border-radius: 5px;
  border: 1px solid;
  z-index: 2;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.tooltip {
  background-color: #fff;
  color: #999;
  border-color: #e0e0e0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media (prefers-color-scheme: dark) {
  .tooltip {
    background-color: #1a1a1a;
    border-color: #333;
  }
}
