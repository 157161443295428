.card {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
  display: block;
  width: 90vw;
  margin-bottom: 40px;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.title h1 {
  padding-left: 20px;
}

.kebab {
  padding-right: 20px;
}

.content {
  padding: 20px;
  padding-top: 0px;
}

.profile-photo {
  border-radius: 50%;
  cursor: pointer;
}

.details p {
  margin-bottom: 5px;
  font-weight: bold;
}

.buttons {
  display: flex;
  margin-top: 10px;
}

.upload-button {
  margin-right: 10px;
}

.edit-button {
  margin-right: 20px;
}

.locations {
  display: flex;
  gap: 20px;
}

.locations-subtitle {
  margin-bottom: 0;
}
/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/
.title-container {
  border-bottom: 1px solid #e0e0e0;
}

.note {
  color: #999;
}

.edit-button {
  background-color: inherit;
  color: inherit;
  border-color: #e0e0e0;
}
.edit-button:hover {
  color: #fff;
}

.delete-button {
  background-color: #ff7f7f;
  border-color: #ff7f7f;
}

.delete-button:hover {
  background-color: red;
  border-color: red;
}
