.error {
  margin: 10vh 0;
  text-align: center;
}

.error h1 {
  font-size: 50px;
  margin-top: 0;
  margin-bottom: 15px;
}

.icon {
  margin-right: 5px;
  display: flex;
  align-items: baseline;
}

.home-link a {
  display: flex;
}

.home-link {
  display: flex;
  margin: 20px;
  justify-content: center;
  align-items: center;
  font-style: bold;
  font-weight: 500;
}
