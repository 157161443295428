.container {
  display: flex;
  position: relative;
}

.user {
  position: absolute;
  right: 75px;
}

.dropdown {
  position: relative;
  display: flex;
  height: 50px;
  width: 61px;
  align-items: center;
}
.dropdown_toggle {
  cursor: pointer;
  padding: 10px;
  margin-right: 15px;
  height: 50px;
  vertical-align: middle;
}
.dropdown_menu {
  position: absolute;
  top: 45px;
  right: 15px;
  /* width: 180px; */
  padding: 10px;
  padding-bottom: 5px;
  border-radius: 10px;
  z-index: 1;
}

.dropdown_menu form {
  display: flex;
  /* padding: 5px 10px; */
}
.dropdown_menu button:hover,
.dropdown_toggle:hover,
.dropdown_menu a:hover {
  border-radius: 10px;
  cursor: pointer;
}
.dropdown_menu button {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin: 5px 0;
  height: 40px;
  padding: 5px 10px;
  font-family: inherit;
  font-size: 14px;
  cursor: pointer;
}
.dropdown_menu a {
  display: flex;
  text-decoration: none;
  align-items: center;
  margin: 5px 0;
  padding: 5px 10px;
  height: 30px;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -webkit-touch-callout: none;
}
.dropdown_menu .menuitem {
  font-size: 14px;
  font-weight: 600;
}

.profile-icon {
  border-radius: 50%;
  margin-right: 15px;
  cursor: pointer;
}

.profile-photo-container {
  height: 69px;
  width: 86px;
}

.profile-photo {
  border-radius: 50%;
  margin-right: 20px;
  padding: 2px;
  border: 1px solid #e0e0e0;
}

.profile-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 0px 10px;
}

.name-detail {
  font-weight: bold;
  margin: 0;
  margin-bottom: 3px;
}

.position-detail {
  font-size: 14px;
  color: #999;
  margin: 0;
  margin-bottom: 3px;
}

.email-detail {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.roles {
  padding-left: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
}

.my-roles {
  margin: 0;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 3px;
}

.role-list {
  margin: 0;
  margin-bottom: 3px;
  color: #999;
}

.icon {
  font-size: 22px;
  padding-right: 10px;
}
.toggleicon {
  padding-right: 0;
  font-size: 26px;
}

.logout-button {
  height: 100%;
  width: 100%;
  /* background-color: red; */
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/
/* .container {
  color: #999;
} */

.icon {
  color: #333;
}

.menuitem,
.dropdown_menu button {
  color: #333;
}

.dropdown_menu {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 15px 25px rgba(0, 0, 0, 0.1);
}

.dropdown_toggle:hover,
.dropdown_menu a:hover,
.dropdown_menu button:hover {
  background: #efefef;
}

@media (prefers-color-scheme: dark) {
  .icon {
    color: #e0e0e0;
  }

  .menuitem,
  .dropdown_menu button {
    color: #fff;
  }

  .dropdown_menu {
    background-color: #222;
    border: 1px solid #1a1a1a;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 15px 25px rgba(0, 0, 0, 0.4);
  }

  .dropdown_toggle:hover,
  .dropdown_menu a:hover,
  .dropdown_menu form:hover {
    background: #333;
  }
}
