.pagination {
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 0 20px;
}

.pagination-grid {
  display: inline-grid;
  grid-template-columns: repeat(5, auto);
  justify-items: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  margin: 11px 0;
  height: 33px;
}

.pageInfo {
  display: flex;
  font-size: 14px;
  letter-spacing: 0.5px;
  align-items: center;
  justify-content: center;
}

.pagecounter {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 33px;
  width: 100px;
  align-items: center;
  padding: 0px 10px;
  justify-content: center;
}

.pagination-grid button {
  height: 33px;
  padding: 8px;
  font-size: 16px;
  font-weight: 800;
  cursor: pointer;
  align-items: center;
  display: flex;
}

.pagination-grid .inactiveButton {
  cursor: not-allowed;
}

.pagination-grid .inactiveButton:hover {
  background-color: inherit;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.pagination {
  border-top: 1px solid #e0e0e0;
}

.pageInfo {
  color: #888;
}

.pagecounter {
  color: #888;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.first-button {
  border-right: 1px solid #e0e0e0;
}

.last-button {
  border-left: 1px solid #e0e0e0;
}

.pagination-grid {
  border: 1px solid #e0e0e0;
}

.pagination-grid button {
  color: #333;
}
.pagination-grid button:hover {
  background-color: #e0e0e0;
}
.pagination-grid .inactiveButton {
  color: #e0e0e0;
}

@media (prefers-color-scheme: dark) {
  .pagination {
    border-top: 1px solid #222;
  }
  .pageInfo {
    color: #666;
  }

  .pagecounter {
    color: #e0e0e0;
    border-left: 1px solid #222;
    border-right: 1px solid #222;
  }

  .pagination-grid {
    border: 1px solid #222;
  }

  .first-button {
    border-right: 1px solid #222;
  }

  .last-button {
    border-left: 1px solid #222;
  }

  .pagination-grid button {
    color: #e0e0e0;
  }

  .pagination-grid button:hover {
    color: #222;
    background-color: #888;
  }

  .pagination-grid .inactiveButton {
    color: #222;
  }

  .pagination-grid .inactiveButton:hover {
    color: #222;
  }
}
