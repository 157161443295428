.customSlider {
  width: 200px;
  max-width: 200px;
}

.customSlider-track {
  height: 2px;
}

.customSlider-thumb {
  top: -6px;
  cursor: pointer;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  outline: none;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.customSlider-track {
  background: #e0e0e0;
}
.customSlider-track-0.customSlider-track {
  background: #add8e6;
}
.customSlider-thumb {
  background: #add8e6;
}
.customSlider-thumb:hover {
  box-shadow: 0 0 0 2px #add8e6;
}
