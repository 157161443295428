.title {
  position: relative;
  margin: 0px;
  text-align: left;
  margin-left: 20px;
}

.title h1 {
  margin: 0px;
}
.title p {
  margin-top: 5px;
}

.control {
  display: flex;
  justify-content: space-between;
}

.control-buttons {
  display: flex;
}

.control-buttons button {
  margin-bottom: 16px;
}

.card {
  display: block;
  padding: 0;
  min-width: min-content;
}

.name {
  display: flex;
  align-items: center;
}

.edit-icon,
.delete-icon,
.share-icon,
.browser-icons {
  padding: 7px;
  cursor: pointer;
}

.browser-up-icon {
  padding: 7px;
  cursor: pointer;
  transform: scaleX(-1);
}

.create-button {
  margin: 0 0 20px 20px;
}

.upload {
  margin: 20px;
}

.navigation {
  display: flex;
  justify-content: space-between;
}

.gridControl {
  margin-right: 20px;
}

.navigation-icons {
  font-size: 22px;
  padding-right: 7px;
}

.navigation-container {
  justify-content: start;
  height: 33px;
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-left: 20px;
}

.breadcrumbs {
  display: flex;
  margin: 0 10px;
  align-items: center;
}
.breadcrumbs > * {
  display: inline-flex;
  align-items: center;
}
.breadcrumbs .crumb:after {
  content: "/";
  margin: 0 10px;
}
.breadcrumbs .crumb:last-child:after {
  display: none;
}

.navbreadcrumbs {
  display: flex;
  margin: 0 10px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.navbreadcrumbs > * {
  display: inline-flex;
  align-items: center;
}
.navbreadcrumbs .crumb:after {
  content: "/";
  margin: 0 10px;
}
.navbreadcrumbs .crumb:last-child:after {
  display: none;
}

.crumb {
  cursor: pointer;
}

.head {
  display: flex;
  align-items: center;
}

/** ----------------------------------------------------------------------------- 
**              Media queries 
**-----------------------------------------------------------------------------*/

@media (max-width: 600px) {
  .breadcrumbs {
    margin-left: 20px;
  }
  .navigation-container,
  .create-button,
  .title {
    margin-left: 0px;
  }
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.share-icon:hover {
  color: #007aff;
}

.delete-icon {
  color: #ff7f7f;
}

.delete-icon:hover {
  color: red;
}

.name,
.name:hover,
.name:visited {
  color: inherit;
}

.breadcrumbs .crumb:after {
  color: #ccc;
}
.breadcrumbs a {
  color: #333;
}
.breadcrumbs svg {
  position: relative;
  top: 2px;
}

@media (prefers-color-scheme: dark) {
  .breadcrumbs .crumb:after {
    color: #333;
  }
  .breadcrumbs a {
    color: #ccc;
  }
}
