.footer {
  text-align: center;
  width: 100%;
  vertical-align: middle;
}

.footer img {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 5px;
}

.link {
  display: flex;
  justify-content: center;
}

.link a {
  display: flex;
  align-items: center;
}

.icon {
  margin: 5px;
}
