.segmented-switch {
  display: inline-block;
  width: 170px !important;
  height: 35px;
  border-radius: 5px;
  background-color: #e0e0e0;
  position: relative;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.segmented-switch .switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 83px;
  height: 31px;
  border-radius: 5px;
  background-color: #fff;
  transition: transform 0.2s ease-in-out;
}

.segmented-switch .switch-handle.on {
  transform: translateX(83px);
}

.segmented-switch .label {
  display: flex;
  align-items: center;
  position: absolute;
  top: 6px;
  font-size: 16px;
  transition: opacity 0.2s ease-in-out;
}

.segmented-switch .label.left {
  left: 10px;
}

.segmented-switch .label.right {
  left: calc(50% + 10px);
}

.segmented-switch .label.active {
  opacity: 1;
}

.segmented-switch .label:not(.active) {
  opacity: 0.3;
}

.icons {
  padding-right: 5px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

@media (prefers-color-scheme: dark) {
  .segmented-switch {
    background-color: #333;
  }

  .segmented-switch .switch-handle {
    background-color: #1a1a1a;
  }

  .segmented-switch .label {
    color: #fff;
  }
}
