.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 50;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 10vh;
  left: 10%;
  z-index: 100;
  overflow: hidden;
  padding: 0;
  left: 50%;
  transform: translateX(-50%);
}

.header {
  padding: 15px;
}

.header h2 {
  margin: 0;
  color: #333;
}

.content {
  padding: 15px;
  text-align: center;
  color: #333;
}

.actions {
  padding: 15px;
  display: flex;
  justify-content: center;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.backdrop {
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(4px);
}
