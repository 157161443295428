.card {
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  padding: 20px;
  border-radius: 10px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  overflow: hidden;
}

@media (prefers-color-scheme: dark) {
  .card {
    background-color: #1a1a1a;
    color: #fff;
    border: 1px solid #222;
  }
}
