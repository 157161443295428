.sidebar-nav {
  width: 279px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -280px;
  transition: 200ms;
  z-index: 10;
  overflow-y: auto;
  scrollbar-width: none;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -webkit-touch-callout: none;
}
.sidebar-nav::-webkit-scrollbar {
  display: none;
}
.sidebar-nav.open {
  left: 0;
}
.nav {
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -webkit-touch-callout: none;
}
.menu-icon {
  margin-left: 15px;
  font-size: 25px;
  height: 25px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.menu-icon:hover {
  border-radius: 10px;
}
.sidebar {
  width: 100%;
}

.icon {
  vertical-align: text-bottom;
}
button {
  font-size: 25px;
  background: inherit;
  border: none;
}
.site-logo img {
  margin: auto;
}

.sidebar-logo {
  text-align: center;
}
.sidebar-logo img {
  display: flex;
  margin: 40px auto;
  margin-bottom: 0px;
}
.menu-title {
  margin-left: 20px;
  margin-top: 30px;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
}
.close {
  display: flex;
  height: 65px;
  align-items: center;
}

.version {
  font-size: 12px;
  color: #999;
}

/** ----------------------------------------------------------------------------- 
**              Media queries 
**-----------------------------------------------------------------------------*/

@media (max-width: 600px) {
  .sidebar-nav {
    width: 100vw;
    left: -100%;
    transition: 200ms;
  }
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.sidebar-nav {
  color: #333;
  background-color: #fff;
  border-right: 1px solid #e0e0e0;
}
.nav {
  background-color: #fff;
  color: #333;
  border-bottom: 1px solid #e0e0e0;
}
.menu-icon:hover {
  background: #efefef;
}
.icon {
  color: #333;
}
.menu-title {
  color: #808080;
}
@media (prefers-color-scheme: dark) {
  .sidebar-nav {
    color: #fff;
    background-color: #222;
    border-right: 1px solid #1a1a1a;
  }
  .nav {
    background-color: #1a1a1a;
    color: #fff;
    border-bottom: 1px solid #222;
  }
  .menu-icon:hover {
    background: #333;
  }
  .icon {
    color: #e0e0e0;
  }
  .menu-title {
    color: #666;
  }
}
