.circle-container {
  width: 50;
  height: 50;
}

.circle-progress {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 12px;
  font-weight: normal;
}

.circle-text.failed {
  font-weight: bold;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.circle-background {
  fill: none;
  stroke: #e0e0e0;
}

.circle-progress {
  fill: none;
  stroke: #999;
}

.circle-progress.done {
  stroke: #007affcc;
}

.circle-progress.failed {
  stroke: #ff7f7f;
}

.circle-progress.warning {
  stroke: #ff863b;
}

.circle-text {
  fill: #999;
}

.circle-text.done {
  fill: #007affcc;
}

.circle-text.failed {
  fill: #ff7f7f;
}

.circle-text.warning {
  fill: #ff863b;
}
