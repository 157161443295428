.container {
  display: flex;
  width: 20%;
  margin-right: 20px;
}

.searchbar {
  width: 100%;
  height: 31px;
  font-size: inherit;
  font-family: inherit;

  border: 1px solid;
  border-left: 0;
  border-right: 0;
}

.search-button,
.options-button,
.clear-button {
  font-size: 20px;
  padding-top: 6px;
  height: 35px;
  border: 1px solid;
}

.clear-button {
  border: 1px 0px;
  border-left: 0;
  border-right: 0;
  cursor: pointer;
}

.options-button {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}

.search-button {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: 0;
}

.searchbar:focus-visible {
  outline: none;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.search-button,
.options-button,
.clear-button {
  color: #999;
  background-color: #fff;
}

.searchbar,
.search-button,
.options-button,
.clear-button {
  border-color: #e0e0e0;
}

.clear-button:hover {
  color: #ff7f7f;
}

.options-button:hover {
  color: #007aff;
}

@media (prefers-color-scheme: dark) {
  .searchbar {
    background-color: #1a1a1a;
    border-color: #222;
    color: #fff;
  }

  .searchbar::placeholder {
    color: #e0e0e0;
  }

  .search-button,
  .options-button,
  .clear-button {
    background-color: #1a1a1a;
    border-color: #222;
  }
}
