.input {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-top: 10px 0;
}

.input input {
  padding-left: 15px;
  font-size: 16px;
  font-family: inherit;
}

.field {
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid;
  margin: 10px 0px 0px 0px;
  height: 35px;
  width: 100%;
}

.label {
  display: flex;
  margin-top: 10px;
}

.note {
  margin-left: 5px;
}

.input-wrapper {
  position: relative;
}

.password-icon {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-20%);
  font-size: 20px;
  cursor: pointer;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.field {
  border-color: #e0e0e0;
  color: #333;
}
.password-icon {
  color: #e0e0e0;
}

.note {
  color: #999;
}

.input input:focus {
  outline: none;
  border-color: #007aff4d;
  box-shadow: 0 0 5px 1px #007aff4d;
}

@media (prefers-color-scheme: dark) {
  .field {
    background-color: #333;
    border-color: #666;
  }

  .input input {
    color: #fff;
  }

  .password-icon {
    color: #666;
  }
}
