.button {
  font: inherit;
  font-weight: 700;
  font-size: 1rem;
  border-radius: 5px;
  height: 35px;
  cursor: pointer;
  padding: 0 10px;
  white-space: nowrap;
}

.label {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.note {
  margin-left: 5px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.button {
  border: 1px solid #007aff;
  background: #007aff;
  color: #ffffff;
}

.button:hover,
.button:active {
  background: #007affcc;
  border-color: #007affcc;
}

.button:focus {
  outline: none;
}

.button:disabled,
.button:disabled:hover,
.button:disabled:active {
  background-color: #cccccc;
  color: #333;
  border-color: #ccc;
  cursor: not-allowed;
}

@media (prefers-color-scheme: dark) {
  .button {
    color: #e0e0e0;
  }

  .button:disabled,
  .button:disabled:hover,
  .button:disabled:active {
    background-color: #333;
    color: #777;
    border-color: #666;
  }
}
