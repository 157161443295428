.progress-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 5px 5px 1px 5px;
  width: 350px;
  right: 25px;
  bottom: 25px;
  transition: 200ms;
  z-index: 5;
  border-radius: 10px;
  /* align-items: center; */
  display: none;
  cursor: pointer;
}

.progress-container.open {
  display: flex;
}

.big-file-progress {
  box-sizing: border-box;
}

.all-file-progress {
  display: flex;
  align-items: center;
}

.progress-text {
  margin-left: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
}

.progress-container span {
  font-size: 14px;
}

.alert {
  color: #ff7f7f;
  font-weight: bold;
}

.warning {
  color: #ff863b;
  font-weight: bold;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.progress-container {
  background-color: #fff;
  color: #999;
  border: 1px solid #e0e0e0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 15px 25px rgba(0, 0, 0, 0.1);
}

@media (prefers-color-scheme: dark) {
  .progress-container {
    background-color: #1a1a1a;
    color: #fff;
    border-color: #222;
  }
}
