/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.tableHeader {
  background-color: #f9f9f9;
}

@media (prefers-color-scheme: dark) {
  .tableHeader {
    background-color: #222;
  }
}
