.button {
  display: flex;
}

.downIcon,
.upIcon,
.defaultIcon {
  margin-left: 10px;
  font-size: 16px;
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.defaultIcon {
  color: #f9f9f9;
}

.button:hover {
  .defaultIcon {
    color: #333;
  }

  .downIcon,
  .upIcon,
  .defaultIcon {
    background-color: #ddd;
  }
}

@media (prefers-color-scheme: dark) {
  .defaultIcon,
  .downIcon,
  .upIcon {
    color: #222;
  }

  .downIcon,
  .upIcon {
    background-color: #666;
  }
}
