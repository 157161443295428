.gridItem {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  justify-content: space-between;
}

.icon {
  width: 180px;
  height: 67px;
}

.image-preview {
  padding-top: 4px;
  padding-bottom: 3px;
  max-width: 180px;
  max-height: 60px;
}

.browser-icons {
  font-size: 50px;
}

.folderName {
  position: relative;
  padding-bottom: 10px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.gridItem:hover {
  background-color: #f5f5f5;
}

@media (prefers-color-scheme: dark) {
  .gridItem:hover {
    background-color: #222;
  }

  .actionButton:hover {
    background-color: #1a1a1a;
  }
}
