.textarea {
  margin-top: 20px;
}

.area {
  display: flex;
  border-radius: 5px;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  margin: 10px 0px 0px 0px;
  width: 100%;
  box-sizing: border-box;
}

textarea.area {
  padding-left: 15px;
  font-family: inherit;
  font-size: medium;
  resize: none;
}

.label {
  display: flex;
  margin-top: 10px;
}

.note {
  margin-left: 5px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.area {
  border-color: #e0e0e0;
}

.note {
  color: #999;
}

textarea.area {
  color: inherit;
}

.area:focus {
  outline: none;
  border-color: #007aff4d;
  box-shadow: 0 0 5px 1px #007aff4d;
}

@media (prefers-color-scheme: dark) {
  textarea.area {
    background-color: #333;
    color: #ffffff;
    border-color: #555;
  }

  .textarea label {
    color: #fff;
  }
}
