.counter {
  margin-left: 20px;
  display: flex;
  align-items: center;
  height: 35px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.counter {
  color: #999;
}
