.container {
  position: relative;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 95vw;
  min-width: 320px;
  overflow: auto;
}

.calendar {
  height: 900px;
  font-size: 16px;
}

.calendar button {
  font-size: 16px;
}
