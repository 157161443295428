.title {
  position: relative;
  margin: 0px;
  text-align: left;
  margin-left: 20px;
}

.title h1 {
  margin: 0px;
}
.title p {
  margin-top: 5px;
}

.control {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: start;
}

.select-container {
  width: 40%;
  justify-content: end;
  display: flex;
  align-items: center;
}

.partnerSelect {
  width: 25%;
  margin-top: 0;
  margin-right: 10px;
}

.search {
  width: 50%;
}

.csv-button {
  margin: 0 0 20px 20px;
}

.name {
  display: flex;
}

.card {
  display: block;
  padding: 0;
  min-width: min-content;
}

.details-icon {
  padding: 7px 14px 7px 0px;
  font-size: 22px;
  cursor: pointer;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.details-icon:hover {
  color: #007aff;
}

.name,
.name:hover,
.name:visited {
  color: inherit;
}
