.location-control {
  display: flex;
  align-items: end;
  justify-content: space-between;
  justify-items: stretch;
  gap: 20px;
}

.location-autocomplete {
  width: 80%;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.clear-location {
  background-color: #ff7f7f;
  border-color: #ff7f7f;
}

.clear-location:hover {
  background-color: red;
  border-color: red;
}
