.container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
  display: block;
  width: 60vw;
  margin-bottom: 40px;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #e0e0e0;
}

.title h1 {
  padding-left: 20px;
}

.company-logo {
  margin-top: 20px;
  margin-left: 20px;
}

.content {
  padding: 20px;
  padding-top: 0px;
}

.partner-locations,
.partner-contacts {
  margin-top: 40px;
}

.create-location,
.create-contact {
  margin-bottom: 20px;
}

.details-icon {
  padding: 7px 17px 7px 0px;
  font-size: 22px;
  cursor: pointer;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.create-location,
.create-contact {
  border-color: #e0e0e0;
  color: inherit;
  background-color: inherit;
}

.create-location:hover,
.create-contact:hover {
  border-color: #007aff;
  color: #fff;
  background-color: #007aff;
}

.details-icon:hover {
  color: #007aff;
}

.link,
.link:hover,
.link:visited {
  color: inherit;
  cursor: pointer;
}
