.container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
  display: block;
  width: 800px;
  margin-bottom: 40px;
}

.title {
  padding-left: 20px;
}

.content {
  padding: 20px;
  padding-top: 0px;
  line-height: 1.6rem;
}

.subtitle {
  padding: 0 20px;
  margin-top: 40px;
  text-align: center;
}

.buttons {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
}

.button-container,
.store-container {
  display: flex;
  justify-content: center;
  margin: 0 5px;
}

.store-container {
  margin-bottom: 20px;
}

.button {
  width: 147.55px;
  height: 46px;
  margin: 4px;
  border-radius: 7px;
}

@media (max-width: 1200px) {
  .buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    justify-content: center;
  }
}

@media (max-width: 650px) {
  .buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    gap: 10px;
    justify-content: center;
  }

  .store-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    justify-content: center;
  }
}
