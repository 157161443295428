.phoneLabel {
  display: block;
  margin-top: 15px;
}

.PhoneInput {
  position: relative;
  margin-top: 10px;
  width: 100%;
}

.PhoneInputCountry {
  position: absolute;
  top: 8px;
  padding: 0 10px 0 15px;
  margin-right: 0;
  font-size: 18px;
}

.PhoneInputInput {
  height: 23px;
  border-radius: 5px;
  font: inherit;
  padding: 5px;
  padding-left: 60px;
}

/** ----------------------------------------------------------------------------- 
**              Color scheme 
**-----------------------------------------------------------------------------*/

.PhoneInputInput {
  background-color: inherit;
  color: inherit;
  border: 1px solid #e0e0e0;
}

.PhoneInputInput:focus {
  outline: none;
  border-color: #007aff4d;
  box-shadow: 0 0 5px 1px #007aff4d;
}

@media (prefers-color-scheme: dark) {
  .PhoneInputInput {
    border-color: #555;
    background-color: #333;
  }
}
